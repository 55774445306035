import axios from "axios";
import { getAccessToken, isEmpty } from "../helpers/utils";

axios.defaults.baseURL = `${process.env.NEXT_PUBLIC_AXIOS_BASE_URL}/`;

if (typeof window !== 'undefined') {
  let token = localStorage.getItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
  if (!isEmpty(token)) {
    axios.defaults.headers.common['x-auth-token'] = getAccessToken();
  }
}

// console.log("axios.defaults.baseURL", axios.defaults.baseURL, process.env.X_AUTH_LABEL);

export default axios

export const post = axios.post
export const put = axios.put
export const patch = axios.put
export const remove = axios.delete
