import Url from "url-parse";

const IS_DEV = process.env.NODE_ENV === 'development';
const IS_BROWSER = typeof window !== 'undefined';
let GOOGLE_REDIRECT_URI;
let APPLE_REDIRECT_URI;

if (IS_DEV) {
  if (IS_BROWSER) {
    let { hostname, protocol } = new Url(window.location.href);
    GOOGLE_REDIRECT_URI = `${protocol}//${hostname}:${process.env.NEXT_PUBLIC_APP_PORT}`;
    APPLE_REDIRECT_URI = `${protocol}//${hostname}:${process.env.NEXT_PUBLIC_APP_PORT}`;
  } else {
    GOOGLE_REDIRECT_URI = "http://localhost:3000";
    APPLE_REDIRECT_URI = "http://localhost:3000";
  }
} else {
  GOOGLE_REDIRECT_URI = process.env.NEXT_PUBLIC_APP_URL;
  APPLE_REDIRECT_URI = process.env.NEXT_PUBLIC_APP_URL;
}

export const APP_PATHS = {
  HOME: '/',
  TRANSACTIONS: '/transactions',
  TRANSACTION: '/transactions/:id',
  TRANSFER: '/transfer',

  SETTING: '/setting',
  PRIVACY: '/privacy-policy',

  TERMS: '/terms',
  REGISTER: `${process.env.NEXT_PUBLIC_APP_PATH}/register`,
  LOGIN: `${process.env.NEXT_PUBLIC_APP_PATH}/login`,
  GOOGLE_LOGIN : `${process.env.NEXT_PUBLIC_AXIOS_BASE_URL}/login/google?redirect_uri=${GOOGLE_REDIRECT_URI}`,
  APPLE_LOGIN : `${process.env.NEXT_PUBLIC_AXIOS_BASE_URL}/login/apple?redirect_uri=${GOOGLE_REDIRECT_URI}`
};
