import jwtDecode from 'jwt-decode';
import jwt_decode from 'jwt-decode';
import axios from '../../api';
import api from '../../api';
import {
  cleanUrl,
  createQueryString,
  encodeToBase46,
  getJwtToken,
  handleNetworkError,
  renewAccessToken
} from "./index";
import { isEmpty } from "../validation";
import log from "../../lib/log";
import platform from "../../lib/platform";
import { APP_PATHS } from "../../config/routes";
import Url from "url-parse";
import { isJwtExpired } from 'jwt-check-expiration';
import {IS_DEV, IS_BROWSER} from "../../config";

export const isValidTokenOl = (accessToken) => {
  console.log('accessToken', accessToken);
  try {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  } catch (e) {
    return false;
  }
};

export const isValidToken = (token) => {
  try {
    if (!token) {
      return false;
    }

    return !isJwtExpired(token);
  } catch (e) {
    return false;
  }
};

const getSession = () => {
  return localStorage.getItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
}

const removeSession = () => {
  localStorage.removeItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL);
  delete axios.defaults.headers.common.Authorization;
  delete axios.defaults.headers.common['x-auth-token'];
}

/**
 *
 * @param jwt {String}
 * @returns {boolean|*}
 */
export const getAccessToken = function (jwt) {
  let token = jwt || getSession();
  if (token) {
    return (JSON.parse(token))['accessToken'];
  }
  return false;
}

const setAuthorizationHeader = (accessToken) => {
  axios.defaults.headers.common.Authorization = `Bearer${process.env.NEXT_PUBLIC_X_AUTH_PREFIX}${accessToken}`;
  axios.defaults.headers.common['x-auth-token'] = accessToken;
  axios.defaults.headers.common['X-Source-Client'] = platform();
}

/**
 *
 * @param token {{ accessToken : string }}
 * @param tokenJSON {String}
 */
const setSession = (token, tokenJSON) => {
  if (token) {
    if (!tokenJSON || typeof tokenJSON !== 'string') {
      tokenJSON = JSON.stringify(token);
    }
    localStorage.setItem(process.env.NEXT_PUBLIC_X_AUTH_LABEL, tokenJSON);

    let { accessToken } = token;
    setAuthorizationHeader(accessToken);

    // This function below will handle when token is expired Bearer
    // const { exp } = jwtDecode(token);
    // handleTokenExpired(exp);
  } else {
    let token = getSession();
    if (!isEmpty(token)) {
      setAuthorizationHeader(getAccessToken(token));
    } else {
      removeSession();
    }
  }
};

export const getIdToken = function () {
  let jwt = getSession();
  if (jwt) {
    return (JSON.parse(jwt))['idToken'];
  }
  return false;
}

export const renewSession = async function () {
  try {
    let token = JSON.parse(getSession());
    let { refreshToken } = token;

    let response = await axios.post(`${process.env.NEXT_PUBLIC_AXIOS_OA_AUTH_URL}/login/renew-token`, {
      refreshToken
    });

    if (response) {
      let { data : { data : newToken } } = response;

      if (response.status === 200) {
        const { accessToken, idToken } = newToken;

        axios.defaults.headers.common['x-auth-token'] = accessToken;
        setSession(newToken, JSON.stringify(newToken));
      }
    }
  } catch (error) {
    handleNetworkError(error, {
      404 : function (error) {
        console.log(error);
      },
      401 : () => {}
    }, "#RENEW_TOKEN_ERROR");
  }
}

export const getNewTokenByInterval = function (refreshInterval) {
  // TODO make sure this is not done when the app is in idle mode
  let interval;
  interval = setInterval(async () => {
    try {
      await renewSession();
    } catch (error) {
      clearInterval(interval);
    }

  }, refreshInterval);
}

export const logout = async function (router, sessionTimeout = false) {
  let queryString = createQueryString({ ...(sessionTimeout && { se : 1, i_redirect_url : router.pathname }) });

  removeSession();
  router.push(`/login?${queryString}`);
}

const decodeUserDetails = (token) => {
  try {
    return jwt_decode(token);
  } catch (e) {
    console.log(e);
  }
}

export const getUserFromSession = function () {
  try {
    let userToken = getIdToken();
    if (userToken) {
      return jwt_decode(userToken);
    }
    return false;
  } catch (e) {
    log("#GET_USER_FROM_SESSION_ERROR", "error", e);
  }
}

export const exchangeCodeForToken = async (code, source) => {
  try {
    let redirectUri = "";
    if (IS_DEV && IS_BROWSER) {
      let { hostname, protocol } = new Url(window.location.href);
      redirectUri = `${protocol}//${hostname}:${process.env.NEXT_PUBLIC_APP_PORT}`;

    } else {
      redirectUri = process.env.NEXT_PUBLIC_APP_URL
    }

    let response = await api.get('/login/token', { params : { code, redirectUri, source } });

    if (response && response.status === 200) {
      console.log('response.data', response.data)
      return response.data;
    }
  } catch (e) {
    log("CODE_FOR_TOKEN_EXCHANGE_ERROR", "ERROR", e);
    throw e;
  }
}

export const handleGoogleLogin = function () {
  if (typeof window !== "undefined") {
    window.location.href = encodeURI(`${APP_PATHS.GOOGLE_LOGIN}`);
  }
}

export const handleAppleLogin = function () {
  if (typeof window !== "undefined") {
    window.location.href = encodeURI(`${APP_PATHS.APPLE_LOGIN}`);
  }
}

export const redirectToFederatedLoginProvider = function (provider = "GOOGLE", a_redirect_url = "", i_redirect_url = "") {
  if (typeof window !== "undefined") {
    if (!isEmpty(a_redirect_url)) {
      sessionStorage.setItem("a_redirect_url", decodeURIComponent(a_redirect_url));
    }
    if (!isEmpty(i_redirect_url)) {
      sessionStorage.setItem("i_redirect_url", i_redirect_url);
    }
    sessionStorage.setItem("f_login_provider", provider);

    if (provider === "GOOGLE") {
      handleGoogleLogin();
    } else if (provider === "APPLE") {
      handleAppleLogin();
    }
  }
}


export { setSession, getSession, removeSession };

/**
 *
 * @param url {String}
 * @param token {String}
 * @param source {String}
 */
export const redirectToSourceWithToken = (url, token = "", source = "") => {
  url = url.split("?")[0];
  const urlObject = new Url(url);

  let OA_AUTH_PATH = "";

  if (IS_DEV) {
    if (IS_BROWSER) {
      let { origin } = window.location;
      OA_AUTH_PATH = origin;
    } else {
      OA_AUTH_PATH = "http://localhost:3000";
    }
  } else {
    OA_AUTH_PATH = process.env.NEXT_PUBLIC_OA_AUTH_PATH;
  }

  let { origin } = urlObject;
  origin = cleanUrl(origin);

  if (isEmpty(token)) {
    token = getJwtToken();
  }

  let queryString = createQueryString({
    ...(source && { source }),
    code : encodeToBase46(token)
  });

  let redirectUrl = `${origin}/login?${queryString}`;

  // window.location.href = redirectUrl;
  window.location.replace(redirectUrl);
}
