import React, { useEffect } from "react";
import { useRouter } from 'next/router'
import { isEmpty } from "../helpers/validation";
import { cleanUrl, getQueryParams } from "../helpers/utils";
import {
  exchangeCodeForToken,
  getSession,
  handleAppleLogin,
  handleGoogleLogin,
  redirectToSourceWithToken,
  setSession
} from "../helpers/utils/session";
import log from "../lib/log";
import { handleNetworkError } from "../helpers/utils/http";

function Index () {
  const router = useRouter();
  let { code = '', source = 'settlement', } = getQueryParams();
  // const [VIEW, setVIEW] = React.useState('REDIRECTING');

  useEffect(() => {
    router.prefetch('/login');
    router.prefetch('/home');
  }, []);

  useEffect(() => {
    if (router.isReady) {
      if (router.query.error_description) {
        const provider = sessionStorage.getItem("f_login_provider");
        sessionStorage.removeItem("f_login_provider");
        const error_description = router.query.error_description;

        if (provider === "GOOGLE") {
          handleGoogleLogin();
        } else if (provider === "APPLE") {
          handleAppleLogin();
        }
      } else {
        initialize(router?.query?.code);
      }
    }
  }, [router.isReady, router.query.error, router.query.code, router.query.error_description]);

  const redirectToDestination = (a_redirect_url = '', i_redirect_url = '', tokenJSON) => {
    if (!isEmpty(a_redirect_url) && a_redirect_url !== 'null') {
      redirectToSourceWithToken(decodeURIComponent(a_redirect_url), tokenJSON);
      // window.location.href = `${a_redirect_url}?code=${encodeToBase46(tokenJSON)}`
    } else if (!isEmpty(i_redirect_url) && i_redirect_url !== 'null') {
      router.push(i_redirect_url)
    } else {
      router.push(`/home`);
    }
  };

  const handleExchangeCodeForToken = async (code) => {
    try {
      const f_login_provider = sessionStorage.getItem("f_login_provider");

      let token = await exchangeCodeForToken(code, source);

      if (token) {
        const tokenJSON = JSON.stringify(token);

        const a_redirect_url = sessionStorage.getItem("a_redirect_url");
        const i_redirect_url = sessionStorage.getItem("i_redirect_url");

        sessionStorage.removeItem("a_redirect_url");
        sessionStorage.removeItem("i_redirect_url");

        setSession(token, tokenJSON);
        redirectToDestination(decodeURIComponent(a_redirect_url), i_redirect_url, tokenJSON);
      }
    } catch (error) {
      console.log('error', error)
      handleNetworkError(error, {
        400 : ({code, message, accessToken, idToken}) => {
          console.log({code, message})
          if (code === 'PhoneNumberNotProvidedError') {
            if (accessToken && idToken) {
              // setSession({accessToken, idToken});
              sessionStorage.setItem('fin-a-t', accessToken);
              sessionStorage.setItem('fin-i-t', idToken);
            }
          router.push('/register/finalize');
          } else if (code === 'PhoneNumberNotVerifiedError') {
            // v=t means verify phone number, v for verify, t for true
            router.push('/register/finalize?v=t');
          }
          else {
            router.push('/login');
          }
        }
      })
    }
  }

  const handleTokenSession = async () => {
    let token = getSession();

    if (token) {
      router.push('/home');
    } else {
      router.push('/login');
    }
  }

  const initialize = async (code) => {
    try {
      if (!isEmpty(code)) {
       await handleExchangeCodeForToken(code);
      } else {
       await handleTokenSession();
      }
    } catch (error) {
      router.push('/login');
      log(error.message, 'error', error);
      // logout();
    }
  }

  return (<p style={{ textAlign : "center", marginTop : 100 }}>Please wait...</p>)
}

/*
export async function getServerSideProps ({ query }) {
  // http://localhost:3000/?error_description=PreSignUp+failed+with+error+There+were+2+validation+errors%3A%0A*+MissingRequiredParameter%3A+Missing+required+key+%27Username%27+in+params%0A*+InvalidParameterType%3A+Expected+params.UserAttributes%5B0%5D.Value+to+be+a+string.+&error=invalid_request
}
*/

export default Index
