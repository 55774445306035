import Url from "url-parse";
import useSWR, { cache } from "../../lib/swr/dist";
import { diceyDialog, logout } from "./index";
import api from "../../api";
import log from "../../lib/log";

/**
 *
 * @param url {String}
 * @returns {{}}
 */
export const getQueryParams = function (url) {
  if (url || typeof window !== 'undefined') {
    url = url ? url : window.location.href;
    let params = {};
    if (url.endsWith("#")) {
      url = url.replace(new RegExp('#' + '$'), '');
    }
    let queryString = url.split('?')[1];
    if (!queryString) {
      return params;
    }
    let queryArray = queryString.split('&');

    queryArray.forEach((param,) => {
      let [ key, value ] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    });

    url = queryString = queryArray = null;

    return params;
  }
  return {}
}

/*export const getQueryParams = function (url) {
  if (url || typeof window !== "undefined") {
    url = url ? url : window.location.href;
    let { query } = parse(url, true);
    return query
  }
  return query
}*/

/**
 *
 * @param url {String}
 * @param {Boolean} [strict]
 * @returns {{}}
 */
export const getQueryParamsArray = function (url, strict = false) {
  if (url || typeof window !== 'undefined') {
    url = url ? url : window.location.href;
    let queryArray = [];

    let queryString = url.split('?')[1];
    if (!queryString) {
      return queryArray;
    }
    queryArray = queryString.split('&').map((param) => {
      let [ key, value ] = param.split('=');
      return !string ? { [key] : value } : [ key, value ]
    });

    url = queryString = null;

    return queryArray;
  }
  return {}
}

/**
 *
 * @param params {Object}
 * @returns {string}
 */
export const createQueryString = (params) => {
  let queryParams = "";

  if (params) {
    Object.entries(params).forEach(([ key, value ], i) => {
       if (value) {
         queryParams += (i > 0 ? "&" : "") + key + "=" + value
       }
    })
  }
  return encodeURIComponent(queryParams)
}

/**
 * @param {String} url
 * @returns {Promise<any>}
 */
export const fetchData = async (url) => {
  try {
    console.log({ url });
    const res = await api(url);
    return res.data;
  } catch (err) {
    let { response : { status, data } = {} } = error;
    status === 401 && logout(null, null, true);

    throw err;
  }
};

/**
 *
 * @param dataKey
 * @param fetcher
 * @param options
 * @returns {SWRResponse<any, any>}
 */
export const useStaleSWR = (dataKey, fetcher, options = {}) => {
  !fetcher && (fetcher = fetchData);
  const revalidationOptions = {
    revalidateOnMount : !cache.has(dataKey), //here we refer to the SWR cache // EXPLORE explore JIT cache import
    revalidateOnFocus : false,
    revalidateOnReconnect : false,
    ...options
  };

  return useSWR(dataKey, fetcher, revalidationOptions);
}

/**
 *
 * @param path {String}
 * @returns {string|*}
 */
export function removeAllPathTrailingSlashes (path) {
  if (path !== "/") {
    let newPath = path;
    let j = path.length - 1;

    while (j >= 0) {
      let len = newPath.length;
      if (newPath.endsWith("/")) {
        newPath = newPath.substring(0, len - 1)
        j--
      } else {
        break;
      }
    }
    return newPath;
  }
  return path
}

export const redirectTo = (path) => {
  window.location.href = path;
}


/**
 *
 * @param error {Error}
 * @param [handlers] {{}}
 * @param [code] {String}
 * @param [isOnline] {String}
 * @param [url] {String}
 */
export const handleNetworkError = function (error, handlers = {}, code = "", isOnline = "", url = "",) {
  log(`${error.message} ${code}`, "error");

  if (error.response) {
    // localising the status object for faster lookup
    let { response : { status, data } = {} } = error;
    if (status === 404) {
      if (typeof handlers[404] !== "function") {
        if (typeof isOnline !== "undefined" && isOnline === false) {
          // let the user know that the page is not found
          diceyDialog({
            icon : "error",
            title : "Error",
            text : "It appears you're offline, please check your network connection and try again",
            timer : null,
          });
        } else {
          diceyDialog({
            title : "Error",
            text : "An Error occurred at our end. Please give it another try in a few moments.",
            timer : null, icon : "error"
          })
        }
      } else {
        handlers[404](data);
      }

    } else if (status === 400) {
      if (typeof handlers[400] !== "function") {
        diceyDialog({
          icon : "error",
          title : "Error",
          text : data.message,
          timer : null,
        });
      } else {
        handlers[400](data);
      }

    } else if (status >= 500 && status < 600) {
      if (typeof handlers[status] !== "function") {
        diceyDialog({
          title : "Error",
          text : "It's not you, it's us. Please give it another try in a few moments.",
          timer : null, icon : "error"
        })
      } else {
        handlers[status](data);
      }

    } else if (Object.keys(handlers).length > 0) {
      console.log(handlers, status);
      if (typeof handlers[status] === "function") {
        handlers[status](data);
      }
    }
  } else {
    if (error.message === "Network Error") {
      diceyDialog({
        title : "Network Error",
        text : "Please check your network connection and try again",
        timer : null, icon : "error"
      })
    } else {
      // display generic error
      diceyDialog({
        icon : 'error',
        title : 'Error',
        text : "Sorry, an error occurred. Please try again in a few moments",
      });
    }
  }
}


const ALLOWED_ORIGINS = [
  'http://localhost:8084',
  'http://localhost:8086',
  'https://staging.stlmt.io',
  'hhtps://pay.oneafrica.io',
];

export function addAccessControlHeaders (res, origin) {
  res.setHeader('Access-Control-Allow-Credentials', true);
  res.setHeader('Access-Control-Allow-Origin', origin);
  res.setHeader('Access-Control-Allow-Methods', 'GET,OPTIONS,POST');
  res.setHeader('Access-Control-Allow-Headers', "Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Credentials, Access-Control-Allow-Origin, Access-Control-Allow-Methods, Access-Control-Allow-Headers, Vary");
  res.setHeader("Vary", "Origin");

  return res
}

export function handleErrorResponse (error, res) {
  if (error.response) {
    const { status, data } = error.response;
    return res.status(status).json(data);
  } else {
    return res.status(500).json({ success : false, code : '#INTERNAL_SERVER_ERROR' });
  }
}

// TODO add possibility to override allowed origins via params
export  function isAllowedRequestOrigin (origin ) {
  return ALLOWED_ORIGINS.includes(origin)
}


export function respondToPreflight (req, res) {
  if (req.method === 'OPTIONS') {
    return res.status(204).end();
  }
}
